html,
body {
    margin: 0;
    font-family: lato;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    * {
        outline: none;
    }
}
body::-webkit-scrollbar,
.mat-select-panel::-webkit-scrollbar,
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transition: height 0.15s ease-out;
}
body::-webkit-scrollbar-track,
.mat-select-panel::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
body::-webkit-scrollbar-thumb,
.mat-select-panel::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    opacity: 0.1;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}
body:hover::-webkit-scrollbar,
.mat-select-panel:hover::-webkit-scrollbar {
    height: 0.6em;
    transition: height 0.25s ease-in;
}
body:hover::-webkit-scrollbar-thumb,
.mat-select-panel:hover::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}
body::-webkit-scrollbar-thumb:hover,
.mat-select-panel::-webkit-scrollbar-thumb:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.55);
}

footer {
    //   padding: 0 15px;
    //   position: fixed;
    //   bottom: 0;
    //   left: 0;
    small {
        font-size: 0.7rem;
        text-transform: uppercase;
    }
}

.vis-tooltip {
    position: absolute !important;
    z-index: 999999999 !important;
    background: var(--white-color);
    box-shadow: 0 1px 23px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 5px;
    font-size: 0.7rem;
    h6 {
        color: #888;
        border-bottom: 1px solid #888;
        padding-bottom: 10px;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.resize .border {
    position: absolute;
    background: red;
}
.resize .border-top,
.resize .border-bottom {
    left: 0;
    right: 0;
    height: 1px;
    cursor: n-resize;
}
.resize .border-left,
.resize .border-right {
    top: 0;
    bottom: 0;
    width: 1px;
    cursor: w-resize;
}
.resize .border-left {
    left: 0;
}
.resize .border-right {
    right: 0;
}
.resize .border-top {
    top: 0;
}

.resize .border-bottom {
    bottom: 0;
}

.resize .border-top-left {
    left: 0;
    top: 0;
    height: 4px;
    width: 4px;
    background: transparent !important;
    cursor: se-resize;
    z-index: 999;
}
.resize .border-top-right {
    right: 0;
    top: 0;
    height: 4px;
    width: 4px;
    background: transparent !important;
    cursor: ne-resize;
    z-index: 999;
}

.resize .border-bottom-left {
    left: 0;
    bottom: 0;
    height: 4px;
    width: 4px;
    background: transparent !important;
    cursor: ne-resize;
    z-index: 999;
}

.resize .border-bottom-right {
    right: 0;
    bottom: 0;
    height: 4px;
    width: 4px;
    background: transparent !important;
    cursor: se-resize;
    z-index: 999;
}

.cdk-global-overlay-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    // opacity: 0.75;
}
.fs-mat-dialog-container {
    .mat-dialog-container {
        box-shadow: none;
        border-radius: 8px;
    }
}

// ends

//for date range selector button color
.md-drppicker {
    padding: 20px !important;
    font-family: var(--font-family) !important;
    font-size: 1em !important;
    max-width: 640px;

    .table-condensed {
        border-spacing: 0px;
    }
    th.month {
        text-transform: uppercase;
        color: var(--text);
        font-size: 14px;
    }
    &.double {
        .calendar {
            max-width: 295px !important;
            th.available.prev,
            th.available.next {
                background: none;
                position: relative;
                vertical-align: middle;
                line-height: 30px;
                &::before {
                    font-family: "Font Awesome 5 Free";
                    content: "\f104";
                    position: absolute;
                    color: var(--primary);
                    vertical-align: middle;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 100%;
                    font-size: 1.3em;
                    text-align: center;
                }
            }

            &.left {
                border-right: 1px solid var(--line);
                th {
                    // &.next {
                    //   display: none;
                    // }
                }
            }
            &.right {
                th {
                    // &.prev {
                    //   display: none;
                    // }
                }
            }
            td,
            th {
                border-radius: 0px !important;
                min-width: 40px;
                height: 30px;
            }
            td {
                span {
                    padding: 0px;
                    color: inherit;
                    font-size: 13px;
                    font-weight: 550 !important;
                }
                &.available:not(.active) {
                    &:hover {
                        background-color: rgba(var(--primary-rgb), 0.1) !important;
                    }
                }

                &::hover {
                    border-radius: 0px !important;
                }
                &.in-range {
                    background-color: rgba(var(--primary-rgb), 0.1) !important;
                    border-radius: 0px !important;
                    color: var(--text) !important;
                }
                &.active {
                    background-color: var(--primary) !important;
                    border-radius: 0px !important;
                    color: var(--white-color) !important;
                }
            }
            th {
                span {
                    padding: 0px;
                }
                &.available:not(.active):not(.prev):not(.next) {
                    &:hover {
                        background-color: rgba(var(--primary-rgb), 0.1) !important;
                    }
                }

                &::hover {
                    border-radius: 0px !important;
                }
            }
        }
    }

    //   td.active,
    //   td.active:hover {
    //     background-color: var(--primary) !important;
    //     border-radius: 0px !important;
    //   }
    .start-date,
    .end-date,
    .in-range {
        color: var(--white-color) !important;
        span {
            opacity: 1 !important;
        }
    }
    .btn {
        color: var(--primary) !important;
        box-shadow: none !important;
        background: transparent !important;
        margin: 0;
        min-width: auto;
        line-height: 25px !important;
    }

    .btn.btn-default::after {
        content: "|";
        color: gray;
        font-size: 19px;
        line-height: 0px;
        margin-left: 13px;
        position: relative;
        top: -1px;
    }
}
.energy-configuration-section {
    .cdk-global-scrollblock {
        position: static !important;
        width: initial !important;
        overflow: hidden;
    }
    .mat-select-arrow {
        border: solid var(--primary);
        border-left: 3px solid var(--primary) !important;
        border-right: 3px solid var(--primary) !important;
        border-width: 0 2px 2px 0 !important;
        display: inline-block;
        padding: 3px;
        opacity: 0.5;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    .mat-form-field.mat-focused .mat-select-arrow,
    .mat-form-field.ng-valid .mat-select-arrow {
        opacity: 1;
    }
    .mat-form-field.mat-focused,
    .mat-form-field {
        .mat-input-element {
            caret-color: rgb(153, 153, 153);
        }
        .mat-form-field-label {
            color: rgb(153, 153, 153);
        }
        .mat-form-field-ripple {
            background-color: inherit;
            height: 1px;
        }
        .mat-form-field-underline {
            background-color: var(--lighter-grey);
        }
    }
    .fs-mat-select {
        &.mat-select-panel {
            transform-origin: 50% top 0px !important;
            position: relative;
            top: 40px;
            transform: translateX(4px) !important;
            &::-webkit-scrollbar {
                width: 0px;
                height: 0;
                transition: height 0.15s ease-out;
            }
            &:hover {
                &::-webkit-scrollbar {
                    width: 8px;
                }
            }
        }
        .mat-option {
            height: 30px !important;
        }
    }
    .custom-input-form-field {
        .mat-form-field-infix {
            width: 200px;
        }
    }
}

.mat-select-search-clear {
    top: 10px;
    background: transparent;
    border: none;
    line-height: 24px;
}
.mat-select-panel {
    position: absolute;
    top: 24px;
    left: 12px;
    font-size: 13px;
    font-weight: 400;
    border-radius: 3px !important;
    border-top-style: none;
  }
  .mat-select[aria-activedescendant] {
    .mat-select-arrow {
      box-sizing: border-box;
      height: 6px !important;
      width: 6px !important;
      border-left: none !important;
      border-right: 2px solid var(--primary) !important;
      border-top: none !important;
      border-style: solid;
      border-color: var(--primary);
      border-width: 0 2px 2px 0;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 0px !important;
    border-right-style: none !important;
  }
  
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0px !important;
    border-left-style: none !important;
  }
  
  .mat-select-panel ng-animating {
    visibility: hidden;
  }

  .vacation-modal-dialog .mat-dialog-container {
    padding: 0 !important;
  }
  .alert-modal-dialog .mat-dialog-container {
    padding: 0 !important;
    overflow: unset !important;
  }

  .custom-dialog-container .custom-dialog-container {
    height: 58vh !important;
  }
  .mat-select-search-panel {
      .mat-option {
          height: 30px !important;
          line-height: 30px !important;
      }
  }

  .sort-order-dialog .mat-dialog-container {
    padding: 0 !important;
    overflow: unset !important;
  }
  .select-dropdown {
    mat-form-field {
        .mat-form-field-wrapper {
          padding-bottom: 0 !important;
        }
        .mat-form-field-infix {
          border-top: none !important;
        }
        .mat-form-field-ripple {
          background-color:var(--primary);
          height: 1px;
        }
        .mat-form-field-underline {
          bottom: 0.25em !important;
          background-color:var(--lighter-grey) !important;
        }
        .cross-icon {
          font-size: 90% !important;
          color: rgba(0, 0, 0, 0.54);
        }
      }

      .mat-select-panel {
        font-size: 13px;
        font-weight: 400;
        border-radius: 3px !important;
        border-top-style: none;
      }
      
     
        .mat-select-placeholder {
          color: rgba(0, 0, 0, 0.87);
        }
      
      .mat-form-field-appearance-outline .mat-form-field-outline-start {
        border-radius: 0px !important;
        border-right-style: none !important;
      }
      
      .mat-form-field-appearance-outline .mat-form-field-outline-end {
        border-radius: 0px !important;
        border-left-style: none !important;
      }
      
      .mat-select-panel ng-animating {
        visibility: hidden;
      }
  }

  .auto-complete-zone {
    mat-option.option-txt {
      height: 30px;
      font-size: 13px;
    }
  }

  
  .otp-email-field .mat-form-field-subscript-wrapper {
    margin-top: 3px !important;
    padding-left: 0 !important;
  }

  .filter-nodes-select {
    mat-option {
        padding: 0 12px !important;
        
        .mat-option-pseudo-checkbox {
            width: 14px !important;
            height: 14px !important;
        }
    }
  }

  .zone-tile-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;

  
  
    .zone-info {
        display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        padding-left: 5px;
        .zone-name {
            font-size: 12px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-left: 3px;
            max-width: calc(100% - 15px);
        }
    
        .status  {
            width: 10px;
            height: 10px;
            border: 1px solid white;
            background: var(--light-grey);
            border-radius: 100%;
            padding-right: 3px;
        }
        .online {
            background: var(--active-color);
        }
    }
}

.zone-version-labels {
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 4px);
    display: table;
    font-size: 10px;
    .module-status {
        display: flex !important;
        padding-right: 3px;
        .status  {
            width: 10px;
            height: 10px;
            border: 1px solid var(--white-color);
            background: var(--light-grey);
            border-radius: 100%;
            padding-right: 3px;
        }
        .online {
            background: var(--active-color);
        }
    }
    div {
        display: table-cell;
        overflow: hidden;
    }
}
.zone-temp-labels-ctnr {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    .zone-temp-labels {
        display: table;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        /* white-space: nowrap; */
        max-width: calc(100% - 4px);
        padding:2px;
        div {
            padding: 0 2px;
            border-right: 1px solid;
            display: table-cell;
            overflow: hidden;

        }
        div:last-child {
            border-right: none !important
        }
    }
}
.rio-master-control{
    max-width: 90vw !important;
}
.rio-master-control .mat-dialog-container{
    padding: 0 0 35px 0 !important;
    overflow: hidden;
}
.rio-master-control .mat-dialog-title{
    margin:0 !important;
}

.special-schedule-modal-dialog .mat-dialog-container {
    padding: 0 0 20px 0 !important;
}

.apply-named-schedule-modal-dialog .mat-dialog-container {
    padding: 0 0 20px 0 !important;
}


.cpmAddPoint-modal-container {
    max-width: 85vw !important;
    .mat-dialog-container {
        padding: 0px !important;
        overflow: hidden;
    }
}

.equipConfig-model-container {
    .mat-dialog-container {
        padding: 10px !important;
        overflow: hidden;
    }
}
.cpm-masterview-equip-modal-overlay {
    backdrop-filter: blur(1px);
    background: rgba(0, 0, 0, 0.7);
}

.cpm-masterview-equip-modal-container {
    .mat-dialog-container {
        padding: 0px !important;
        overflow: visible;
        background: transparent !important;
        box-shadow: none !important;
    }
    margin-top: 10px !important;
    // margin-left: 10%;
    // margin-right: 10%;
    min-width: 1178px !important;
    min-height: 700px !important;
}

.addAlert-modal-container {
    padding: 0px !important;
    overflow: hidden;
}
  
.switch.switch-small {
    min-width: 25px !important;
    height: 16px !important;
    width: 30px !important;
}
button.switch.switch-small :hover {
    background-color: rgba(var(--primary-rgb), 0.1) !important;
}

// .switch {
//     border: none !important;
//     display: inherit;
// }

.switch.switch-small small {
    width: 12px !important;
    height: 12px !important;
    top: 2px;
    bottom: 1px;
    right: calc(100% - 14px);
}

.switch.checked small {
    right: 2px !important;
    top: 2px;
}

.switch.checked {
    background-color: var(--primary) !important;
}

.confirmation-model-container,
.addPlant-model-container {
    .mat-dialog-container {
        padding: 25px !important;
        overflow: hidden;
    }
}

.pointLoader{
    text-align: center;
}