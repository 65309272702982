/* You can add global styles to this file, and also import other style files */
@import url("./assets/css/fontawesome-6.1.1/css/all.min.css");
@import url("./assets/css/fontawesome-6.1.1/css/regular.min.css");


@import "./assets/css/styles.scss";
@import "skeleton-screen-css";

@font-face {
  font-family: 'Lato';
  src: url("assets/font/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Lato-Bold';
  src: url("assets/font/Lato-Bold.ttf") format("truetype");
}

// Equip Data 
.display-block-important {
  display: block !important;
}
.display-none-important {
  display: none;
}

.equipview-container, .alerts-container {
  width: 730px;
  padding: 15px 5px 15px 15px;
  font-size: 1.65rem !important;
  position: absolute;
  background: rgba(32, 32, 32, .9);
  text-transform: uppercase;
  font-family: 'Oswald', Lato;
  font-weight: 400;
  border-radius: 10px;
  border-left: 7px solid black;
  border-right: 7px solid black;
  min-width: 110px !important;
  z-index: 8;
}

.alerts-container{
  top: 5%;
  right: 70%;
  width: 1069px;
  max-height: 1200px;
  padding-left: 25px;
  border-radius: 43px;
  border-left: 7px solid black;
  border-right: 7px solid black;
  max-width: 56rem;
}

.alerts-content{
  overflow-y: auto;
  max-height: 43rem;
}


.waterCooledChiller, .airCooledChiller{
  .alerts-container{
    top:15%;
  }
}

.airCooledChiller{
  .hoverCompressorContainer,
  .hoverEvaporatorContainer{
    right:-120%;
  }
}
.alertDiv{
  margin-top: 44px!important;
}

.alerts-heading{
  margin-top: 75px;
  font-size: 33px;
  color: var( --light-grey);
  margin-bottom: 23px;
  line-height: 19px;
}

.alerts-equipName{
  margin-top: 6px;
  font-size: 33px;
  color:  var(--white-color);
  margin-bottom: -51px;
  
}
.alerts-status, .alerts-status-off{
  margin-top: 6px;
  font-size: 33px;
  color: #279e06;
  margin-bottom: -51px;
  align-items: end;
  right: 4%;
  position: absolute;
  
}

.alerts-status-off{
  color:  var( --light-grey);
}

.mr-auto {
  margin-right: auto !important;
}

.alertDescription{
  color:  var(--white-color);
  font-size: 25px;
  padding-top: 15px;
}


.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.alert-low {
  color: #106AB5;
  white-space: nowrap;
  font-size: 28px;
  line-height: 16px;
}

.alert-moderate {
  color: #FFB047;
  white-space: nowrap;
  font-size: 28px;
  line-height: 16px;
}

.alert-high {
  color:  #FC4F4F;
  white-space: nowrap;
  font-size: 28px;
  line-height: 16px;
}

.equipview-pointValue {
  color: #9CD1FF;
  padding-right: 10px;
  white-space: nowrap !important;
}

.alert-time-generated {
  color: var( --light-grey);
  padding-right: 10px;
  white-space: nowrap;
  margin-top: -7px;
}

.alert-time-generated {
  color: var( --light-grey);
  padding-right: 10px;
  white-space: nowrap;
  margin-top: -7px;
}

.alert-time-generated {
  color: var( --light-grey);
  padding-right: 10px;
  white-space: nowrap;
  margin-top: -7px;
}

.equipview-pointName {
  color: var(--lighter-grey);
}

.red {
  color: var(--primary) !important;
}

select {
  height: 30px;
  line-height: 30px;
  font-size: 27px;
  width: auto;
  /* color: red; */
  border: none;
  background: rgba(32, 32, 32, .0);
  color: var(--primary) !important;
  text-align-last: right;
}

select option {
  background: rgba(32, 32, 32, .0) !important;
  font-size: 10px !important;
}

.masterPointSelectOption:checked {
  background-color: rgba(0,0,0,.12) !important;
  color: var(--primary) !important;
}

option:hover:not(:checked)  {
  background-color: rgba(0,0,0,.12) !important;
}

.masterPointSelectOption {
  font-size: 12px!important;
  background-color: var(--white-color) !important;
  padding-top: 30px!important;
  padding-bottom: 30px!important;
  margin-top: 30px!important;
  margin-bottom: 30px!important;
  text-align: left !important;
  color: rgba(0, 0, 0, .7) !important;

  &:hover{
    background-color: #ebeced !important;
  }
}

.openCollapseIcon, .openCollapseIconLeft {
  position: absolute;
  font-size: 35px;
  color: var(--primary);
  cursor: pointer;
  z-index: 999999999 !important;
}

.openCollapseIcon {
  right: -50px !important;
}

.openCollapseIconLeft {
  right: auto;
  left: -50px;
}

.openExpandIcon {
  position: absolute;
  font-size: 35px;
  top: 40px;
  left: 8px;
  color: var(--primary);
  cursor: pointer;
  z-index: 999999;
}

.openExpandIconLeft{
  position: absolute;
  font-size: 35px;
  top: -32px;
  right: 138px;
  color: var(--primary);
  cursor: pointer;
  z-index: 999999;
}

.equipNameStatusBar {
  position: absolute;
  top: -50px;
  color: var( --light-grey);
  margin-left: -16px;
  width: 400px !important;
  font-size: 1.7rem !important;
}

.equipNameCollapsedBar, .equipNameCollapsedBarLeft {
  color: var( --light-grey);
  display: none;
  position: absolute;
  right: -200px !important;
  top: -50px !important;
  text-transform: uppercase;
  font-family: 'Oswald', Lato;
  font-weight: 400;
  font-size: 25px;
}

.pointContainerHeight {
  height: auto;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mat-button-toggle-label-content {
  line-height: 20px !important;
}

.statusButton {
  color: var(--primary) !important;
}

.mat-button-toggle-checked {
  background-color: var(--primary) !important;

  .statusButton {
    color: #fff !important;
  }
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 5px !important;
}

.font-size-5 {
  font-size: 5px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.mat-button-toggle-group-appearance-standard,
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
  border: 1px solid var(--primary) !important;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
  border-left: solid 1px var(--primary) !important;
}

.statusIcon {
  width: 15px;
  height: 15px;
  margin-top: -4px;
}


.trendGraphContainer {
  position: sticky;
  right: 140px;

  svg {
    z-index: 10 !important;
    position: initial !important;
    overflow: visible !important;
  }
}

.equipNamewithoutPoint {
  position: absolute;
  color: var( --light-grey);
  margin-left: -16px;
  width: 400px !important;
  font-size: 25px;
  text-transform: uppercase;
  font-family: 'Oswald', Lato;
  font-weight: 400;
  display: none;
}

// Status View styles Starts here

.equipNameContainerStatusView {
  position: absolute;
  text-transform: uppercase;
  font-family: "Oswald", Lato;
  font-weight: 400;
}

.equipName-title {
  position: absolute;
  color: var(--light-grey);
  margin-left: -16px;
  width: 400px !important;
  font-size: 1.7rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//Equip MasterView Style 

.waterCooledChiller {
  .equipNamewithoutPoint {
    top: 100px !important;
    right: 0 !important;
  }

  .equipview-container {
    top: -98px;
    left: 28%;
  }

  .masterlineSvg {
    left: 29%;
    top: 70px;
  }

  .equipNameContainerStatusView {
    top: 57px;
    left: 28%;
    .equipName-title {
      top: -97px;
    }
  }

  .equipNameCollapsedBar {
    right: 366px !important;
    top: -45px !important;
  }
}

.coolingTowerSingleFan {
  .equipNamewithoutPoint {
    top: 24px !important;
    left: 550px !important;
  }

  .equipNameContainerStatusView {    
    left: 68%;
    top: -97px;
  }

  .equipview-container {
    top: -100px;
    left: 70%;
  }

  .masterlineSvg {
    left: 68%;
  }

  .equipNameCollapsedBar {
    right: 120px !important;
    top: -100px !important;
  }
}

.coolingTowerTwoFan {
  .equipNamewithoutPoint {
    top: 24px !important;
    left: 550px !important;
  }

  .equipview-container {
    top: -92px;
    left: 70%;
  }

  .equipNameContainerStatusView {    
    left: 70%;
    top: -97px;
  }

  .masterlineSvg {
    left: 70%;
  }

  .equipNameCollapsedBar {
    right: 200px !important;
    top: -100px !important;
  }
}

.airCooledChiller {
  .equipNamewithoutPoint {
    top: 85px !important;
    right: -80px !important;
  }

  .equipview-container {
    top: -70px;
    left: 70%;
  }

  
  .equipNameContainerStatusView {
    top: 57px;
    left: 70%;
    .equipName-title {
      top: -97px;
    }
  }

  .masterlineSvg {
    left: 70%;
    top: 70px;
  }

  .equipNameCollapsedBar {
    right: 100px !important;
    top: -60px !important;
  }
}

.steamBoiler {
  .equipNamewithoutPoint {
    top: 24px !important;
    right: 0 !important;
  }

  .equipview-container {
    top: -183px;
    left: 35%;
  }

  .equipNameContainerStatusView {
    top: 57px;
    left: 28%;
    .equipName-title {
      top: -97px;
    }
  }

  .masterlineSvg {
    left: 29%;
    top: 70px;
  }

  .equipNameCollapsedBar {
    right: 235px !important;
    top: -30px !important;
  }
}

.condenserBoiler {
  .equipNamewithoutPoint {
    top: 24px !important;
    right: 0 !important;
  }

  .equipview-container {
    top: -117px;
    left: 27%;
  }

  .equipNameContainerStatusView {
    top: 57px;
    left: 28%;
    .equipName-title {
      top: -97px;
    }
  }

  .masterlineSvg {
    left: 29%;
    top: 70px;
  }

  .equipNameCollapsedBar {
    right: 370px !important;
    top: -30px !important;
  }
}

.coolingTowerFourFan {
  .equipNamewithoutPoint {
    top: 24px !important;
    left: 600px !important;
  }

  .equipview-container {
    top: -100px;
    left: 35%;
  }

  .equipNameContainerStatusView {    
    left: 48%;
    top: -97px;
  }

  .masterlineSvg {
    left: 48%;
  }

  .equipNameCollapsedBar {
    right: 845px !important;
    top: -90px !important;
  }
}

.building2Pipe,.building2PipeHot, .dualTempBuilding2Pipe {
  .equipNamewithoutPoint {
    top: 25px!important;
    left: 330px;
  }

  .equipNameContainerStatusView {    
    left: 97%;
    top: -32px;
  }

  .equipview-container {
    top: -130px !important;
    left: 37% !important;
  }
  .masterlineSvg {
    left: 98%;
    top: 75px;
  }

  .equipNameCollapsedBar {
    top: 25px!important;
    left: 330px;
  }
  .equipNameCollapsedBarLeft {
    top: -29px !important;
    left: 700px;
  }

  .openExpandIconLeft {
    top: -43px !important;
    left: 0px;
  }
}

.building4Pipe {
  .equipNamewithoutPoint {
    top: 25px!important;
    left: 330px;
  }

  .equipNameContainerStatusView {    
    left: 102%;
    top: -55px;
  }

  .equipview-container {
    top: -130px !important;
    left: 750px !important;
  }
  
  .masterlineSvg {
    left: 98%;
    top: 75px;
  }

  .equipNameCollapsedBar {
    top: 25px!important;
    left: 330px;
  }
  .equipNameCollapsedBarLeft {
    top: -66px !important;
    left: 733px;
  }

  .openExpandIconLeft {
    top: -44px !important;
    left: 10px;
  }
}

.pump1Vertical_forward,
.pump1Vertical_backward {
  .equipNamewithoutPoint {
      top: 90px !important;
      right: 0 !important;
    }
  .equipNameCollapsedBarLeft{
    top: -91px !important;
    left: 99px !important;
    width: 294px !important;
  }
}

.CWSupplyClass-pump1Vertical_backward,
.CWSupplyClass-pump1Vertical_forward,
.boilerSupplyClass-pump1Vertical_forward,
.boilerSupplyClass-pump1Vertical_backward,
.DTSupplyClass-pump1Vertical_forward,
.DTSupplyClass-pump1Vertical_backward{
  .equipview-container {
    top: 395px;
    left: 273px;
  }

  .equipNameContainerStatusView {
    top: 400px;
    right: 20px;
  }

  .equipName-title {
    top: 10px;
    left: -190px;
  }

  .equipNameCollapsedBar{
    right: 82px !important;
    top: 375px !important;
  }

  .equipNameCollapsedBarLeft{
    top: 440px !important;
    left: 75px !important;
  }
}

.CWReturnClass-pump1Vertical_backward,
.CWReturnClass-pump1Vertical_forward {
  .equipview-container {
    top: 482px;
    right: 234px;
  }

  .equipNameContainerStatusView {
    top: 400px;
    right: 20px;
  }

  .equipName-title {
    top: 94px;
    right: 207px;
  }

  .equipNameCollapsedBar{
    
  }

  .equipNameCollapsedBarLeft {
    top: 500px !important;
    left: 105px !important;
  }
}


.CDWReturnClass-pump1Vertical_backward,
.CDWReturnClass-pump1Vertical_forward{
  .equipview-container {
    top: -127px !important;
    right: 48% !important;
  }

  .equipNameContainerStatusView {
    top: -120px!important;
    left: 90%!important;
  }

  .equipName-title {
    top: 12px;
    right: 178px;
  }

  .equipNameCollapsedBar{
    top: -148px !important;
    left: 53% !important;
  }
}

.CDWSupplyClass-pump1Vertical_backward,
.boilerReturnClass-pump1Vertical_backward,
.boilerReturnClass-pump1Vertical_forward,
.HotBypassClass-pump1Vertical_backward
.DTBypassClass-pump1Vertical_backward,
.CDWBypassClass-pump1Vertical_backward,
.CWBypassClass-pump1Vertical_backward,
.HotBypassClass-pump1Vertical_forward,
.DTBypassClass-pump1Vertical_forward,
.CDWBypassClass-pump1Vertical_forward,
.CWBypassClass-pump1Vertical_forward,
.DTReturnClass-pump1Vertical_backward,
.DTReturnClass-pump1Vertical_forward{
  .equipview-container {
    top: -220px !important;
    left: 56% !important;
  }

  .equipNameContainerStatusView {
    top: -120px!important;
    left: 90%!important;
  }

  .equipName-title {
    top: -97px;
    left: -155px;
  }

  .equipNameCollapsedBar{
    top: -230px !important;
    left: 56% !important;
  }
}

.CDWSupplyClass-pump1Vertical_forward {
  .equipview-container {
    top: -225px!important;
    right: 30%!important;
  }

  .equipNameContainerStatusView {
    top: -120px!important;
    left: 90%!important;
  }

  .equipName-title {
    top: -97px;
    right: -70px;
  }

  .equipNameCollapsedBarLeft {
    top: -210px !important;
    left: 26% !important;
  }
}

.valveActuator_backward, .valveActuator_forward {
  .equipNamewithoutPoint {
    top: -16px !important;
    left: 100px !important;
  }

  .equipNameCollapsedBar {
    left: 133px!important;
    top: 19px!important;
    width: 350px !important;
  }
  .equipNameCollapsedBarLeft {
    left: 110px !important;
    top: 55px !important;
    width: 350px !important;
  }
}

.CWReturnClass-valveActuator_backward,
.CWReturnClass-valveActuator_forward{
  .equipview-container, .equipNameContainerStatusView {
    top: 450px;
    right: 0px;
  }

  .equipNameCollapsedBarLeft{
    left: -101px !important;
    top: 525px !important;
    right: 0px !important;
  }
}

.CWSupplyClass-valveActuator_backward,
.CWSupplyClass-valveActuator_forward,
.boilerSupplyClass-valveActuator_forward,
.boilerSupplyClass-valveActuator_backward,
.DTSupplyClass-valveActuator_forward,
.DTSupplyClass-valveActuator_backward{
  .equipview-container,.equipNameContainerStatusView {
    top: 250px;
    right: 20px;
  }

  .equipNameCollapsedBarLeft{
    top: 347px !important;
    right: 71px !important;
    left: -107px !important;
  }
}

.CDWSupplyClass-valveActuator_backward,
.boilerReturnClass-valveActuator_forward,
.boilerReturnClass-valveActuator_backward,
.HotBypassClass-valveActuator_forward,
.DTBypassClass-valveActuator_forward,
.CDWBypassClass-valveActuator_forward,
.CWBypassClass-valveActuator_forward,
.HotBypassClass-valveActuator_backward,
.DTBypassClass-valveActuator_backward,
.CDWBypassClass-valveActuator_backward,
.CWBypassClass-valveActuator_backward,
.DTReturnClass-valveActuator_forward,
.DTReturnClass-valveActuator_backward{
  .equipview-container, .equipNameContainerStatusView {
    top: -100px;
    left: 114%;
  }

  .equipNameCollapsedBar{
    top: -150px !important;
    left: 90% !important;
  }
}

.CDWSupplyClass-valveActuator_forward {
  .equipview-container {
    top: -100px;
    right: 20%;
  }

  .equipNameContainerStatusView {
    top: -95px;
    right: 405%;
  }

  .equipNameCollapsedBarLeft{
    top: -110px !important;
    left: -85% !important;
  }
}

.CDWReturnClass-valveActuator_backward {
  .equipview-container,.equipNameContainerStatusView {
    top: -300px;
    left: 115%;
  }

  .equipNameCollapsedBar{
    top: -350px !important;
    left: 90% !important;
  }
}

.CDWReturnClass-valveActuator_forward {
  .equipview-container {
    top: -300px;
    right: 20%;
  }

  .equipNameContainerStatusView {
    top: -295px;
    right: 405%;
  }
  .equipNameCollapsedBarLeft {
    top: -305px !important;
    left: -85% !important;
  }
}


.temperatureSensor_forward,
.temperatureSensor_backward {
  .equipNamewithoutPoint {
    top: -25px !important;
    left: 80px !important;
  }

  .equipNameCollapsedBar {
    left: 91px !important;
    top: -3px !important;
    width: 350px !important;
  }
  .equipNameCollapsedBarLeft {
    left: -178px!important;
    top: 99px!important;
    width: 350px !important;
  }
}

.CWSupplyClass-temperatureSensor_backward,
.CWSupplyClass-temperatureSensor_forward,
.boilerSupplyClass-temperatureSensor_backward,
.boilerSupplyClass-temperatureSensor_forward,
.DTSupplyClass-temperatureSensor_backward{
  .equipview-container{
    top: 200px;
    right: 15px;
  }

  .equipNameContainerStatusView {
    right: -35px;
    top: 220px;
  }

  .equipNameCollapsedBarLeft{
    top: 300px !important;
    right: 15px !important; 
  }
}

.DTSupplyClass-temperatureSensor_forward {
  .equipview-container {
    top: 200px;
    left: 65px;
  }

  .equipNameContainerStatusView {
    right: -35px;
    top: 220px;
  }

  .equipNameCollapsedBar {
    top: 190px !important;
    left: 60px !important;
  }
}

.building4Pipe-boilerSupplyClass-temperatureSensor_forward {
  .equipview-container {
    top: 200px;
    left: 90px;
  }

  .equipNameContainerStatusView {
    right: -35px;
    top: 220px;
  }

  .equipNameCollapsedBarLeft {
    top: 243px !important;
    left: 63px !important;
  }
}

.CWReturnClass-temperatureSensor_backward,
.CWReturnClass-temperatureSensor_forward {
  .equipview-container {
    top: 400px;
    right: 15px;
  }

  .equipNameContainerStatusView {
    top: 420px;
    right: -35px;
  }

  .equipNameCollapsedBarLeft{
    top: 500px !important;
    right: 15px !important;
  }
}


.building4Pipe-boilerReturnClass-temperatureSensor_forward {
  .equipview-container {
    top: 421px;
    left: 65px;
  }

  .equipNameContainerStatusView {
    top: 421px;
    left: 65px;
  }

  .equipNameCollapsedBar{
    top: 415px !important;
    left: 65px !important;
  }
}

.CDWReturnClass-temperatureSensor_backward {
  .equipview-container {
    top: -350px;
    left: 35px;
  }
  .equipNameContainerStatusView {
    top: -350px;
    left: 55px;
  }
  .equipNameCollapsedBar{
    top: -400px !important;
    left: 5px !important;
  }
}

.CDWSupplyClass-temperatureSensor_backward,
.boilerReturnClass-temperatureSensor_backward,
.boilerReturnClass-temperatureSensor_forward,
.HotBypassClass-temperatureSensor_backward,
.DTBypassClass-temperatureSensor_backward,
.CDWBypassClass-temperatureSensor_backward,
.CWBypassClass-temperatureSensor_backward,
.HotBypassClass-temperatureSensor_forward,
.DTBypassClass-temperatureSensor_forward,
.CDWBypassClass-temperatureSensor_forward,
.CWBypassClass-temperatureSensor_forward,
.DTReturnClass-temperatureSensor_backward,
.DTReturnClass-temperatureSensor_forward{
  .equipview-container {
    top: -150px;
    left: 35px;
  }

  .equipNameContainerStatusView {
    top: -150px;
    left: 55px;
  }
  .equipNameCollapsedBar{
    top: -200px !important;
    left: 5px !important;
  }
}

.wellTemperature_forward,
.wellTemperature_backward {
  .equipNamewithoutPoint {
    top: -25px !important;
    left: 80px !important;
  }

  .equipNameCollapsedBar {
    left: 76px !important;
    top: -45px !important;
    width: 350px !important;
  }
  .equipNameCollapsedBarLeft {
    left: 76px !important;
    top: 55px !important;
    width: 350px !important;
  }
}

.CDWSupplyClass-temperatureSensor_forward, .building4Pipe-CWReturnClass-temperatureSensor_forward {
  .equipview-container {
    top: -150px;
    right: 35px;
  }
  
  .equipNameContainerStatusView {
    top: -145px;
    right: 405px;
  }
  .equipNameCollapsedBarLeft {
    top: -155px !important;
    left: -190px !important;
  }
}

.building4Pipe-CWSupplyClass-temperatureSensor_forward {
  .equipview-container {
    top: -350px;
    right: 35px;
  }
  .equipNameContainerStatusView {
    top: -345px;
    right: 405px;
  }
  .equipNameCollapsedBarLeft {
    top: -355px !important;
    left: -188px !important;
  }
}

.CDWReturnClass-temperatureSensor_forward {
  .equipview-container {
    top: -350px;
    right: 35px;
  }
  .equipNameContainerStatusView {
    top: -345px;
    right: 405px;
  }
  .equipNameCollapsedBarLeft {
    top: -355px !important;
    left: -188px !important;
  }
}

.CWSupplyClass-wellTemperature_backward,
.CWSupplyClass-wellTemperature_forward,
.boilerSupplyClass-wellTemperature_backward,
.boilerSupplyClass-wellTemperature_forward,
.DTSupplyClass-wellTemperature_backward,
.DTSupplyClass-wellTemperature_forward{
  .equipview-container, .equipNameContainerStatusView {
    top: 200px;
    // left: -585px;
    right: 0px;
  }

  .equipNameCollapsedBarLeft{
    top: 300px !important;
    right: 0px !important;
  }
}

.CWReturnClass-wellTemperature_backward,
.CWReturnClass-wellTemperature_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: 400px;
    // left: -585px;
    right: 0px;
  }

  .equipNameCollapsedBarLeft{
    top: 500px !important;
    right: 0px !important;
  }
}


.CDWReturnClass-wellTemperature_backward,
.CDWReturnClass-wellTemperature_forward{
  .equipview-container, .equipNameContainerStatusView {
    top: -200px;
    left: 140%;
  }

  .equipNameCollapsedBar{
    top: -250px !important;
    left: 140% !important;
  }
}

// .CDWReturnClass-wellTemperature_forward{
//   .equipview-container, .equipNameContainerStatusView {
//     top: -200px;
//     right: 140%;
//   }

//   .equipNameCollapsedBar{
//     top: -250px !important;
//     right: 140% !important;
//   }
// }

// .CDWSupplyClass-wellTemperature_forward {
//   .equipview-container,
//   .equipNameContainerStatusView {
//     top: -300px;
//     right: 140%;
//   }

//   .equipNameCollapsedBar {
//     top: -350px !important;
//     right: 140% !important;
//   }
// }

.CDWSupplyClass-wellTemperature_backward,
.CDWSupplyClass-wellTemperature_forward,
.boilerReturnClass-wellTemperature_backward,
.boilerReturnClass-wellTemperature_forward,
.HotBypassClass-wellTemperature_backward,
.DTBypassClass-wellTemperature_backward,
.CDWBypassClass-wellTemperature_backward,
.CWBypassClass-wellTemperature_backward,
.HotBypassClass-wellTemperature_forward,
.DTBypassClass-wellTemperature_forward,
.CDWBypassClass-wellTemperature_forward,
.CWBypassClass-wellTemperature_forward,
.DTReturnClass-wellTemperature_backward,
.DTReturnClass-wellTemperature_forward {
  .equipview-container, .equipNameContainerStatusView {
    top: -300px;
    left: 140%;
  }

  .equipNameCollapsedBar{
    top: -350px !important;
    left: 140% !important;
  }
}

.pressureSensor_backward
.pressureSensor_forward {
  .equipNamewithoutPoint {
    top: -15px !important;
    left: 100px !important;
  }

  .equipNameCollapsedBar {
    left: 110px !important;
    top: -45px !important;
    width: 350px !important;
  }
  .equipNameCollapsedBarLeft {
    left: 110px !important;
    top: 55px !important;
    width: 350px !important;
  }
}

.CWReturnClass-pressureSensor_backward,
.CWReturnClass-pressureSensor_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: 400px;
    // left: -500px;
    right: 0px;
  }

  .equipNameCollapsedBarLeft{
    top: 500px !important;
    right: 0px !important;
  }
}

.CWSupplyClass-pressureSensor_backward,
.CWSupplyClass-pressureSensor_forward,
.boilerSupplyClass-pressureSensor_backward,
.boilerSupplyClass-pressureSensor_forward,
.DTSupplyClass-pressureSensor_backward,
.DTSupplyClass-pressureSensor_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: 200px;
    // left: -500px;
    right: 0px;
  }

  .equipNameCollapsedBarLeft{
    top: 300px !important;
    right: 0px !important;
  }
}

.CDWReturnClass-pressureSensor_backward,
.CDWReturnClass-pressureSensor_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: -390px;
    left: 140px;
  }

  .equipNameCollapsedBar{
    top: -440px !important;
    left: 110px !important; 
  }
}

.CDWSupplyClass-pressureSensor_backward,
.CDWSupplyClass-pressureSensor_forward,
.boilerReturnClass-pressureSensor_backward,
.boilerReturnClass-pressureSensor_forward,
.HotBypassClass-pressureSensor_backward,
.DTBypassClass-pressureSensor_backward,
.CDWBypassClass-pressureSensor_backward,
.CWBypassClass-pressureSensor_backward,
.HotBypassClass-pressureSensor_forward,
.DTBypassClass-pressureSensor_forward,
.CDWBypassClass-pressureSensor_forward,
.CWBypassClass-pressureSensor_forward
.DTReturnClass-pressureSensor_backward,
.DTReturnClass-pressureSensor_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: -180px;
    left: 140px;
  }

  .equipNameCollapsedBar{
    top: -230px !important;
    left: 110px !important;   
  }
}

.diffPressureSensor_forward,
.diffPressureSensor_backward{
  .equipNamewithoutPoint {
    top: -15px !important;
    left: 100px !important;
  }

  .equipNameCollapsedBar {
    left: 193px!important;
    top: 33px!important;
    width: 350px !important;
  }
  .equipNameCollapsedBarLeft {
    left: -279px!important;
    top: 95px!important;
    width: 350px !important;
  }
}


.CWReturnClass-diffPressureSensor_backward,
.CWReturnClass-diffPressureSensor_forward{
  .equipview-container {
    top: 400px;
    right: 0px;
  }

  .equipNameContainerStatusView {
    top: 400px;
    left: 170px;
  }

  .equipNameCollapsedBarLeft{
    top: 500px !important;
    right: 0px !important;
    left: -86px!important;
  }
}

.CWSupplyClass-diffPressureSensor_backward,
.CWSupplyClass-diffPressureSensor_forward,
.boilerSupplyClass-diffPressureSensor_backward,
.boilerSupplyClass-diffPressureSensor_forward,
.DTSupplyClass-diffPressureSensor_backward,
.DTSupplyClass-diffPressureSensor_forward{
  .equipview-container {
    top: 200px;
    // left: -500px;
    right: 0px;
  }

  .equipNameContainerStatusView {
    top: 200px;
    right: -50px;
  }

  .equipNameCollapsedBarLeft{
    top: 300px !important;
    right: 0px !important; 
    left: -87px!important;
  }
}

.CDWReturnClass-diffPressureSensor_backward {
  .equipview-container,.equipNameContainerStatusView {
    top: -390px;
    left: 140px;
  }

  .equipNameCollapsedBar{
    top: -440px !important;
    left: 110px !important;
  }
}

.CDWReturnClass-diffPressureSensor_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: -365px;
    right: 5px;
  }

  .equipNameContainerStatusView {
    top: -360px;
    right: 390px;
  }

  .equipNameCollapsedBarLeft{
    top: -375px !important;
    left: -90px !important;
  }
}

.CDWSupplyClass-diffPressureSensor_backward,
.boilerReturnClass-diffPressureSensor_backward,
.boilerReturnClass-diffPressureSensor_forward,
.HotBypassClass-diffPressureSensor_backward,
.DTBypassClass-diffPressureSensor_backward,
.CDWBypassClass-diffPressureSensor_backward,
.CWBypassClass-diffPressureSensor_backward,
.HotBypassClass-diffPressureSensor_forward,
.DTBypassClass-diffPressureSensor_forward,
.CDWBypassClass-diffPressureSensor_forward,
.CWBypassClass-diffPressureSensor_forward,
.DTReturnClass-diffPressureSensor_backward,
.DTReturnClass-diffPressureSensor_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: -180px;
    left: 140px;
  }

  .equipNameCollapsedBar{
    top: -230px !important;
    left: 110px !important; 
  }
}

.CDWSupplyClass-diffPressureSensor_forward {
  .equipview-container,.equipNameContainerStatusView {
    top: -170px;
    right: 5px;
  }

  .equipNameContainerStatusView {
    top: -165px;
    right: 390px;
  }

  .equipNameCollapsedBarLeft {
    top: -175px !important;
    left: -90px !important; 
  }
}


.flowSensor_backward,
.flowSensor_forward {
  .equipNamewithoutPoint {
    top: -6px !important;
    left: 150px !important;
  }

  .equipNameCollapsedBar {
    left: 168px!important;
    top: 19px!important;
    width: 350px !important;
  }
  .equipNameCollapsedBarLeft {
    left: -193px!important;
    top: 5px!important;
    width: 278px!important;
  }
}


.CWSupplyClass-flowSensor_backward,
.CWSupplyClass-flowSensor_forward,
.boilerSupplyClass-flowSensor_forward,
.boilerSupplyClass-flowSensor_backward,
.DTSupplyClass-flowSensor_forward,
.DTSupplyClass-flowSensor_backward{
  .equipview-container, .equipNameContainerStatusView {
    top: 449px;
    right: 20px;
  }

  .equipNameCollapsedBarLeft{
    top: 500px!important;
    right: 20px!important;
    left: -9px!important;
  }
}

.CWReturnClass-flowSensor_backward,
.CWReturnClass-flowSensor_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: 400px;
    right: 20px;
  }

  .equipNameCollapsedBarLeft{
    top: 500px !important;
    right: 20px !important;
  }
}


.CDWReturnClass-flowSensor_backward {
  .equipview-container,.equipNameContainerStatusView {
    top: -250px;
    left: 130px;
  }

  .equipNameCollapsedBar{
    top: -300px !important;
    left: 100px !important;
  }
}

.CDWReturnClass-flowSensor_forward{
  .equipview-container {
    top: -310px;
    right: 20px;
  }
  .equipNameContainerStatusView {
    top: -305px;
    right: 405px;
  }
  .equipNameCollapsedBarLeft {
    top: -315px !important;
    left: -10px !important;
  }
}



.CDWSupplyClass-flowSensor_backward,
.boilerReturnClass-flowSensor_forward,
.boilerReturnClass-flowSensor_backward,
.HotBypassClass-flowSensor_forward,
.DTBypassClass-flowSensor_forward,
.CDWBypassClass-flowSensor_forward,
.CWBypassClass-flowSensor_forward,
.HotBypassClass-flowSensor_backward,
.DTBypassClass-flowSensor_backward,
.CDWBypassClass-flowSensor_backward,
.CWBypassClass-flowSensor_backward,
.DTReturnClass-flowSensor_forward,
.DTReturnClass-flowSensor_backward{
  .equipview-container,.equipNameContainerStatusView {
    top: -50px;
    left: 130px;
  }

  .equipNameContainerStatusView {
    top: -50px;
    left: 160px;
  }

  .equipNameCollapsedBar{
    top: -100px !important;
    left: 100px !important;
  }
}

.CDWSupplyClass-flowSensor_forward {
  .equipview-container,
  .equipNameContainerStatusView {
    top: -110px;
    right: 20px;
  }
  .equipNameContainerStatusView {
    top: -105px;
    right: 405px;
  }
  .equipNameCollapsedBarLeft {
    top: -120px !important;
    left: -10px !important;
  }
}

.flowSwitch_backward
.flowSwitch_forward {
  .equipNamewithoutPoint {
    top: -6px !important;
    left: 150px !important;
  }

  .equipNameCollapsedBar {
    left: 135px !important;
    top: -45px !important;
    width: 350px !important;
  }
  .equipNameCollapsedBarLeft {
    left: 135px !important;
    top: -45px !important;
    width: 350px !important;
  }
}

.CWSupplyClass-flowSwitch_backward,
.CWSupplyClass-flowSwitch_forward,
.boilerSupplyClass-flowSwitch_backward,
.boilerSupplyClass-flowSwitch_forward,
.DTSupplyClass-flowSwitch_backward,
.DTSupplyClass-flowSwitch_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: -55px ;
    // left: 170px ;
    right: 0px;
  }

  .equipNameCollapsedBarLeft{
    top: 45px !important ;
    right: 0px !important;
  }
}

.CWReturnClass-flowSwitch_backward,
.CWReturnClass-flowSwitch_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: -55px ;
    // left: 170px ;
    right: 0px;
  }

  .equipNameCollapsedBarLeft{
    top: 45px !important;
    right: 0px !important;
  }
}

.CDWSupplyClass-flowSwitch_backward,
.CDWSupplyClass-flowSwitch_forward,
.boilerReturnClass-flowSwitch_backward,
.boilerReturnClass-flowSwitch_forward,
.HotBypassClass-flowSwitch_backward,
.DTBypassClass-flowSwitch_backward,
.CDWBypassClass-flowSwitch_backward,
.CWBypassClass-flowSwitch_backward,
.HotBypassClass-flowSwitch_forward,
.DTBypassClass-flowSwitch_forward,
.CDWBypassClass-flowSwitch_forward,
.CWBypassClass-flowSwitch_forward,
.DTReturnClass-flowSwitch_backward,
.DTReturnClass-flowSwitch_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: -55px ;
    left: 170px ;
  }

  .equipNameCollapsedBar{
    top: -105px !important;
    left: 140px !important;
  }
}

.CDWReturnClass-flowSwitch_backward,
.CDWReturnClass-flowSwitch_forward{
  .equipview-container,.equipNameContainerStatusView {
    top: -55px ;
    left: 170px ;
  }

  .equipNameCollapsedBar{
    top: -105px !important;
    left: 140px !important;
  }
}

.btuMeter {
  .equipNamewithoutPoint {
    top: -8px !important;
    left: 125px !important;
  }

  .equipNameCollapsedBar {
    left: 115px !important;
    top: -45px !important;
    width: 253px!important;
  }
  .equipNameCollapsedBarLeft {
    left: -194px!important;
    top: 15px!important;
    width: 253px!important;
  }

}

.CWSupplyClass-btuMeter,
.CWReturnClass-btuMeter,
.boilerSupplyClass-btuMeter
.DTSupplyClass-btuMeter{
  .equipview-container,.equipNameContainerStatusView {
    top: 250px;
    right: 0px;
  }

  .equipNameCollapsedBarLeft{
    top: 350px !important;
    right: 0px !important;
    left: 13px!important;
  }
}


.CDWSupplyClass-btuMeter,
.CDWReturnClass-btuMeter,
.boilerReturnClass-btuMeter,
.HotBypassClass-btuMeter,
.DTBypassClass-btuMeter,
.CDWBypassClass-btuMeter,
.CWBypassClass-btuMeter,
.DTReturnClass-btuMeter{
  .equipview-container,.equipNameContainerStatusView {
    top: -55px;
    left: 140px;
  }

  .equipNameCollapsedBar{
    top: -105px !important;
    left: 110px !important;
  }
}

.CWSupplyClass-threeWayValveDivert_backward,
.CWSupplyClass-threeWayValvemixing_backward,
.CWSupplyClass-threeWayValveDivert_forward,
.CWSupplyClass-threeWayValvemixing_forward,
.boilerSupplyClass-threeWayValveDivert_backward,
.boilerSupplyClass-threeWayValvemixing_backward,
.boilerSupplyClass-threeWayValveDivert_forward,
.boilerSupplyClass-threeWayValvemixing_forward,
.DTSupplyClass-threeWayValveDivert_backward,
.DTSupplyClass-threeWayValvemixing_backward,
.DTSupplyClass-threeWayValveDivert_forward,
.DTSupplyClass-threeWayValvemixing_forward {
  .equipview-container,.equipNameContainerStatusView {
    top: 250px;
    right: 45px;
  }

  .equipNameCollapsedBarLeft {
    top: 321px !important;
    left: -7px !important;
    right: -20px !important;
  }
}

.CWReturnClass-threeWayValveDivert_backward,
.CWReturnClass-threeWayValvemixing_backward,
.CWReturnClass-threeWayValveDivert_forward,
.CWReturnClass-threeWayValvemixing_forward {
  .equipview-container,.equipNameContainerStatusView {
    top: 450px;
    right: 45px;
  }

  .equipNameCollapsedBarLeft {
    top: 500px !important;
    right: -20px !important;
    left: -7px !important;
  }
}

.CDWSupplyClass-threeWayValveDivert_backward,
.CDWSupplyClass-threeWayValvemixing_backward,

.boilerReturnClass-threeWayValveDivert_backward,
.boilerReturnClass-threeWayValvemixing_backward,
.boilerReturnClass-threeWayValveDivert_forward,
.boilerReturnClass-threeWayValvemixing_forward,
.HotBypassClass-threeWayValveDivert_backward,
.DTBypassClass-threeWayValveDivert_backward,
.CDWBypassClass-threeWayValveDivert_backward,
.CWBypassClass-threeWayValveDivert_backward,
.HotBypassClass-threeWayValvemixing_backward,
.DTBypassClass-threeWayValvemixing_backward,
.CDWBypassClass-threeWayValvemixing_backward,
.CWBypassClass-threeWayValvemixing_backward,
.HotBypassClass-threeWayValveDivert_forward,
.DTBypassClass-threeWayValveDivert_forward,
.CDWBypassClass-threeWayValveDivert_forward,
.CWBypassClass-threeWayValveDivert_forward,
.HotBypassClass-threeWayValvemixing_forward,
.DTBypassClass-threeWayValvemixing_forward,
.CDWBypassClass-threeWayValvemixing_forward,
.CWBypassClass-threeWayValvemixing_forward,
.DTReturnClass-threeWayValveDivert_backward,
.DTReturnClass-threeWayValvemixing_backward,
.DTReturnClass-threeWayValveDivert_forward,
.DTReturnClass-threeWayValvemixing_forward {
  .equipview-container,.equipNameContainerStatusView {
    top: -100px;
    left: 100px;
  }

  .equipNameCollapsedBar {
    top: -105px !important;
    left: 100px !important;
  }
}

.CDWSupplyClass-threeWayValveDivert_forward,
.CDWSupplyClass-threeWayValvemixing_forward {
  .equipview-container{
    top: -100px;
    right: 75px;
  }

  .equipNameContainerStatusView {
    top: -100px;
    right: 440px;
  }

  .equipNameCollapsedBarLeft {
    top: -105px !important;
    left: -35px !important;

    .openExpandIconLeft{
      right: 245px !important;
    }
  }
}

.CDWReturnClass-threeWayValveDivert_forward,
.CDWReturnClass-threeWayValvemixing_forward {
  .equipview-container {
    top: -215px;
    right: 75px;
  }

  .equipNameContainerStatusView {
    top: -215px;
    right: 440px;
  }

  .equipNameCollapsedBarLeft {
    top: -220px !important;
    left: -30px !important;

    .openExpandIconLeft{
      right: 245px !important;
    }
  }
}

.CDWReturnClass-threeWayValvemixing_backward,
.CDWReturnClass-threeWayValveDivert_backward {
  .equipview-container,.equipNameContainerStatusView {
    top: -295px;
    left: 100px;
  }

  .equipNameCollapsedBar {
    top: -300px !important;
    left: 100px !important;
  }
}

.heatExchanger_backward {
  .equipNamewithoutPoint {
    top: -150px !important;
    left: 65%;
  }

  .equipview-container,.equipNameContainerStatusView {
    top: -150px;
    left: 65%;
  }

  .equipNameCollapsedBarLeft{
    right: 100px !important;
    top: -100px !important;
  }

  .equipNameCollapsedBar {
    left: 115px !important;
    top: 0px !important;
    width: 350px !important;
  }
}

.heatExchanger_forward {
  .equipNamewithoutPoint {
    top: -150px !important;
    right: 55%;
  }

  .equipview-container {
    top: -150px;
    right: 55%;
  }

  .equipNameContainerStatusView {
    top: -150px;
    right: 75%;
  }

  .equipNameCollapsedBarLeft{
    right: 367px !important;
    top: -144px !important;

    .openExpandIconLeft {
      right: 0px !important;
    }
  }
}

.connectTwo {
  .equipNamewithoutPoint {
    top: -37px !important;
    left: -20px;
  }
}

.pointGroupHeader {
  font-weight: bolder;
  font-size: 24px;
  white-space: nowrap;
  color: var( --light-grey);
}


.hoverCompressorContainer {
  top: 20%;
  left: 100%;
  width: 80rem;
  padding :0 50px 50px;
  font-size: 1.65rem !important;
  position: absolute;
  background-color: rgba(32, 32, 32, .9);
  text-transform: uppercase;
  font-family: 'Oswald', Lato;
  font-weight: 400;
  border-radius: 10px;
  border-left: 7px solid black;
  border-right: 9px solid black;
  min-width: 110px !important;
  z-index: 20000 !important;
  min-height: 315px;
  backdrop-filter: blur(5px);
  clip-path: polygon(0 64px,64px 0,calc(100% - 64px) 0,100% 64px,100% calc(100% - 64px),calc(100% - 64px) 100%,64px 100%,0 calc(100% - 64px));
  display: none;
}

.hoverCompressorHeight {
  max-height: 430px;
  overflow-y: scroll;
}

.hoverCoolingTowerTitle {
  text-align: center;
  font-size: 34px;
  color: #fff;
  background: #0000004D;
  padding: 5px;
  text-transform: uppercase;
  margin: 0px 230px 30px;
}

.hoverChillerTitle::before {
  content: "";
  position: fixed;
  top: 2px;
  left: 384px;
  width: 0;
  color: #363636;
  height: 0px;
  border-bottom: 79px solid #363636;
  border-right: 56px solid transparent;
}

.hoverChillerTitle::after {
  content: "";
  position: fixed;
  top: 0px;
  right: 384px;
  width: 0;
  color: #363636;
  height: 0px;
  border-bottom: 79px solid #363636;
  border-left: 56px solid transparent;
}

.hoverCoolingTowerTitle::before {
  content: "";
  position: fixed;
  top: 2px;
  left: 280px;
  width: 0;
  color: #363636;
  height: 0px;
  border-bottom: 79px solid #363636;
  border-right: 56px solid transparent;
}

.hoverCoolingTowerTitle::after {
  content: "";
  position: fixed;
  top: 0px;
  right: 280px;
  width: 0;
  color: #363636;
  height: 0px;
  border-bottom: 79px solid #363636;
  border-left: 56px solid transparent;
}

.coolingTowerTwoFan, .coolingTowerFourFan {
  // .hoverViewBottomStyle {
  //   width: 375px;
  //   left: 36%;
  // }

  // .hoverViewBottomStyle::after {
  //   right: 34%;
  // }

  // .hoverViewBottomStyle::before {
  //   left: 33%;
  // }
}

.hoverCompressorContainer {
  .hoverViewBottomStyle {
    width: 375px;
    left: 36%;
  }

  .hoverViewBottomStyle::after {
    right: 413px;
  }

  .hoverViewBottomStyle::before {
    left: 33%;
  }
}

.hoverViewBottomStyle {
  text-align: center;
  font-size: 34px;
  color: #fff;
  background: rgba(0,0,0,.3019607843);
  padding: 6px;
  text-transform: uppercase;
  position: absolute;
  width: 275px;
  height: 18px;
  bottom: 0;
  left: 34%;
}

.hoverViewBottomStyle::after {
  content: "";
  position: fixed;
  bottom: 0px;
  right: 34%;
  width: 0;
  transform: rotate(145deg);
  color: #363636;
  height: 0px;
  border-bottom: 47px solid #363636;
  border-left: 36px solid transparent;
}

.hoverViewBottomStyle::before {
  content: "";
  position: fixed;
  bottom: 2px;
  left: 29%;
  width: 0;
  color: #363636;
  height: 0px;
  border-bottom: 79px solid #363636;
  border-right: 56px solid transparent;
  transform: rotate(310deg);
}

.darkMode{

  .hoverCoolingTowerTitle,
  .hoverChillerTitle {
    background:  var(--dark-grey) !important;
  }

  .hoverCoolingTowerTitle::before,
  .hoverChillerTitle::before  {
    color: #363636 !important;
    border-bottom: 79px solid rgba(32, 32, 32, 1) !important;
  }
  
  .hoverCoolingTowerTitle::after,
  .hoverChillerTitle::after {
    color: #212020;
    border-bottom: 79px solid rgba(32, 32, 32, 1)!important;
  }

  .hoverViewBottomStyle {
    background: var(--dark-grey) !important;
  }

  .hoverViewBottomStyle::after {
    color: #363636!important;
    border-bottom: 47px solid rgba(32, 32, 32, 0.9)!important;
  }
  
  .hoverViewBottomStyle::before {
    color: #363636!important;
    border-bottom: 79px solid rgba(32, 32, 32, 0.9)!important;
  }

  .hoverCompressorContainer {
    border-left: 7px solid var(--dark-grey);
    border-right: 9px solid var(--dark-grey);
    .hoverViewBottomStyle {
      width: 375px;
      left: 36%;
    }
  
    .hoverViewBottomStyle::after {
      right: 413px;
    }
  
    .hoverViewBottomStyle::before {
      left: 33%;
    }
  }
}

.equipview-container,
.alerts-container,
.hoverCoolingTowerContainer,
.hoverBoilerContainer {
  border-left: 7px solid var(--dark-grey) !important;
  border-right: 7px solid var(--dark-grey) !important;
}

.hoverCoolingTowerPointList {
  text-align: center;
  font-size: 34px;
  color: #fff;
  background: #0000004D;
  padding: 15px;
  text-transform: uppercase;
}

.hoverCompressorTitle {
  text-align: center;
  font-size: 32px;
  color: #fff;
  background: #0000004D;
  padding: 15px;
  margin: 0 230px 30px;
}

.hoverChillerTitle {
  text-align: center;
  font-size: 32px;
  color: #fff;
  background: #0000004D;
  padding: 15px;
  margin: 0 335px 30px;
}

.compressorPointStyle {
  background: #0000004D;
  color: var( --light-grey);
  padding: 15px;
  font-size: 1.7rem;
  text-transform: uppercase;
}

.compressorPointText {
  color: var(--lighter-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 550px;
  padding-right: 10px;
}

.compressorPointValue {
  color: #fff;
  white-space: nowrap;
}

.pump1Vertical {
  .hoverCoolingTowerContainer {
    left: 115%;
  }
}
.hoverCoolingTowerContainer {
  min-width: 60rem !important;
  min-height: 25rem !important;
  top: 20%;
  left: 120%;
  padding: 0px 50px 50px 50px;
  font-size: 1.65rem !important;
  position: absolute;
  background-color: rgba(32, 32, 32, 0.9);
  font-family: 'Oswald', Lato;
  font-weight: 400;
  border-radius: 10px;
  border-left: 7px solid black;
  border-right: 7px solid black;
  z-index: 1100 !important;
  backdrop-filter: blur(5px);
  display: none;
  clip-path: polygon(0 64px,64px 0,calc(100% - 64px) 0,100% 64px,100% calc(100% - 64px),calc(100% - 64px) 100%,64px 100%,0 calc(100% - 64px));
}

.chartSubText {
  color: var( --light-grey);
  text-align: center;
  padding-bottom: 15px;
}

.borderBtwChartValue {
  border-left: 5px solid #202020;
  font-size: 35px;
  padding-right: 46px;
}

.hoverCondensorContainer {
  top: 128%;
  left: 115%;
  width: 930px;
  padding: 0 50px 35px;
  font-size: 1.65rem !important;
  position: absolute;
  background-color:rgba(32, 32, 32, 0.9) ; ;
  text-transform: uppercase;
  font-family: 'Oswald', Lato;
  font-weight: 400;
  border-radius: 10px;
  border-left: 7px solid black;
  border-right: 9px solid black;
  min-width: 110px !important;
  z-index: 20000 !important;
  min-height: 315px;
  backdrop-filter: blur(5px);
  clip-path: polygon(0 64px,64px 0,calc(100% - 64px) 0,100% 64px,100% calc(100% - 64px),calc(100% - 64px) 100%,64px 100%,0 calc(100% - 64px));
}

.circularChart {
  height: 270px;
  width: 250px;
}

.areaChart {
  height: 347px;
  width: 250px;
  padding-top: 22px;
  margin-bottom: 96px;
}

.setPointHeadingOnClickState{
  padding-right: 12px;
}

.chilledWaterLine1 {
  fill: none;
  stroke: #9cc8e3;
  stroke-width: 4px;
}

.condenserWaterLine1 {
  fill: none;
  stroke: #12D681;
  stroke-width: 4px;
}

.condenserWaterLine2 {
  fill: none;
  stroke: #7EF3C2;
  stroke-width: 4px;
  opacity: 0.7;
}

.chilledWaterLine2 {
  fill: none;
  stroke: #54d5fb;
  stroke-width: 4px;
}

.chilledWaterarea1 {
  fill: #9CD1FF;
  opacity: 0.2;
}

.condenserWaterarea1 {
  fill:#7EF3C2;
  opacity: 0.2;
}

.chilledWaterarea2 {
   opacity: 0.8;
  fill: url(#chilledWaterGradientID);
}

.condenserWaterarea2 {
   opacity: 0.8;
  fill: url(#condenserWaterGradientID);
}

.setPointDropDownContainer{
  font-size: 30px;
  padding-left: 64px;
}


.hoverCondensorHeight {
  overflow-y: scroll;
  max-height: 500px;
}

.bulletChartWidget{
  margin-top: 30px;
  width: 100% !important;
}


.bulletTempLabel {
  padding-top: 12px;
  font-weight: 700;
  font-size: 2rem;
}

.condenser-bulletChart {
  .leavingColor {
    color: #7EF3C2;
  }

  .enterColor {
    color: #12D681;
  }

  .deltaText {
    color: #009D59;
  }

  .bullet .line1 {
    stroke: #009D59;
  }

  .bullet .range.s1 {
    fill: #7EF3C2;
  }
  
  .bullet .range.s2 {
    fill: #12D681;
  }
}

.condenser-enterTempbulletChart{

  .leavingColor {
    color: #7EF3C2;
  }

  .enterColor {
    color: #12D681;
  }

  .deltaText {
    color: #009D59;
  }

  .bullet .line1 {
    stroke: #009D59;
  }

  .bullet .range.s1 {
    fill: #12D681 !important;
  }
}


.condenser-leaveTempbulletChart{
  .leavingColor {
    color: #7EF3C2;
  }

  .enterColor {
    color: #12D681;
  }

  .deltaText {
    color: #009D59;
  }

  .bullet .line1 {
    stroke: #009D59;
  }

  .bullet .range.s1 {
    fill: #7EF3C2;
  }
}

.condenser-setpointTempbulletChart{
  .bullet .line1 {
    stroke: #009D59;
  }
}



.evaporator-bulletChart {
  .leavingColor {
    color: #4EABFF;
  }

  .enterColor {
    color: #9CD1FF;
  }

  .deltaText {
    color: #0782F1
  }

  .bullet .line1 {
    stroke: #0782F1;
  }

  .bullet .range.s2 {
    fill: #4EABFF;
  }
  
  .bullet .range.s1 {
    fill: #9CD1FF;
  }
}

.evaporator-enterTempbulletChart{
  .leavingColor {
    color: #4EABFF;
  }

  .enterColor {
    color: #9CD1FF;
  }

  .deltaText {
    color: #0782F1
  }

  .bullet .line1 {
    stroke: #0782F1;
  }

  .bullet .range.s2 {
    fill: #4EABFF;
  }
  
  .bullet .range.s1 {
    fill: #9CD1FF;
  }
}

.evaporator-leaveTempbulletChart{
  .leavingColor {
    color: #4EABFF;
  }

  .enterColor {
    color: #9CD1FF;
  }

  .deltaText {
    color: #0782F1
  }

  .bullet .line1 {
    stroke: #0782F1;
  }

  .bullet .range.s1 {
    fill: #4EABFF;
  }
}

.evaporator-setpointTempbulletChart{
  .bullet .line1 {
    stroke: #0782F1;
  }
}

.boiler-bulletChart{
  .leavingColor {
    color: #FF5147;
  }

  .enterColor {
    color: #FF9F59;
  }

  .deltaText {
    color: #E32E23
  }

  .bullet .line1 {
    stroke: #E32E23;
  }

  .bullet .range.s2 {
    fill: #FF9F59;
  }
  
  .bullet .range.s1 {
    fill: #FF5147;
  }
}

.boiler-setpointTempbulletChart{
  .bullet .line1 {
    stroke: #E32E23;
  }
}


.boiler-leaveTempbulletChart{
  .leavingColor {
    color: #FF5147;
  }

  .enterColor {
    color: #FF9F59;
  }

  .deltaText {
    color: #E32E23
  }

  .bullet .line1 {
    stroke: #E32E23;
  }
  
  .bullet .range.s1 {
    fill: #FF5147;
  }
}


.boiler-enterTempbulletChart{
  .leavingColor {
    color: #FF5147;
  }

  .enterColor {
    color: #FF9F59;
  }

  .deltaText {
    color: #E32E23
  }

  .bullet .line1 {
    stroke: #E32E23;
  }

  .bullet .range.s1 {
    fill: #FF9F59;
  }
}


.bulletValueType{
  color: var(--lighter-grey);
}

.bulletChartPointName{
  font-weight: 500;
  font-size: 2rem;
  color: #fff;
}

.bulletChartSetPointText {
  color: var(--lighter-grey);
  font-size: 1.7rem;
  padding-top: 8px;

  span {
    color: #fff;
    padding-left: 10px;
    font-size: 1.9rem;
    font-weight: 400;
  }
}

.bulletDeltaText {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
  white-space: nowrap;

  span {
    padding-left: 10px;
    padding-right: 10px
  }
}

.bulletChartHeight{
  height: 170px;
}

.bulletChartHeight1{
  height: 135px;
}

.bullet {
  position: inherit !important;
}

.bullet .marker {
  stroke: #fff;
  stroke-width: 15px;
  stroke-linecap: round;
}

.bullet .line1 {
  stroke-width: 8px;
  stroke-linecap: round;
}

.bullet .tick {
  font-size: 25px;
}

.bullet .tick line {
  stroke: #66666699;
  stroke-width: 3px;
}

.bullet .range.s0 {
  fill: #66666699;
}

.secondaryRect0{
  fill: #2b2b2be6 !important;
}

 .secondaryRect1{
  fill: #66666699 !important;
}

 .secondaryRect2{
  // fill: rgba(32,32,32,.9) !important;
  fill: #2b2b2be6 !important;
}

// .bullet .measure.s0 {
//   fill: steelblue;
//   display: none;
// }

.bullet .title {
  font-size: 14px;
  font-weight: bold;
}

.bullet .subtitle {
  fill: var( --light-grey);
}

.bullet .bulletTickText {
  font-size: 30px;
  fill: var( --light-grey);
}

.hoverEvaporatorContainer,.hoverBoilerContainer{
  top: 11%;
  right: -136%;
  width: 930px;
  padding: 0 50px 50px;
  font-size: 1.65rem !important;
  position: absolute;
  background-color: rgba(32, 32, 32, 0.9);
  text-transform: uppercase;
  font-family: 'Oswald', Lato;
  font-weight: 400;
  border-radius: 10px;
  border-left: 7px solid black;
  border-right: 7px solid black;
  min-width: 110px !important;
  z-index: 20000 !important;
  min-height: 315px;
  backdrop-filter: blur(5px);
  clip-path: polygon(0 64px,64px 0,calc(100% - 64px) 0,100% 64px,100% calc(100% - 64px),calc(100% - 64px) 100%,64px 100%,0 calc(100% - 64px));
  display: none;
}

.hoverEvaporatorContainer{
  top: 36% !important;
  right: -136% !important;
}

.chilledAndCondenserWater{
  top: 11%;
  right: -150%;
  width: 930px;
  padding: 0 50px 35px;
  font-size: 1.65rem !important;
  position: absolute;
  background-color: rgba(32, 32, 32, 0.9);
  text-transform: uppercase;
  font-family: 'Oswald', Lato;
  font-weight: 400;
  min-width: 110px !important;
  z-index: 20000 !important;
  min-height: 315px;
}

.hoverBoilerContainer{
  top: 30% !important;
}

.hoverEvaporatorHeight {
  overflow-y: scroll;
  max-height: 500px;
}

.displayNone{
  display: none;
}

.hoverCoolingTowerHeight {
  max-height: 455px;
  overflow-y: scroll;
}

.CoolingTowerPointStyle {
  background: #0000004D;
  color: var( --light-grey);
  padding: 15px;
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
}

.graphPointHeaderCTSingle_WaterPump {
  color: var(--white-color);
  padding-top: 6%;
  font-size: 36px;
}

.graphPointHeader {
  color: var(--white-color);
  padding-top: 13%;
  font-size: 36px;
}

.outer-chart-color {
  color: #FFB134;
}

.inner-chart-color {
  color: #FF5147;
}
.graphPointValText {
  width: 142px;
  display: inline-block;
  font-size: 46px;
  padding-top: 10px;
}


// .bullet { font: 10px sans-serif; }
// .bullet .marker { stroke: #fff; stroke-width: 5px;   stroke-linecap: round;}
// .bullet  .line11 {
// stroke: #009D59;
//     stroke-width: 2px;
//     stroke-linecap: round;
// }
// .bullet .tick line { stroke: #666; stroke-width: .5px; }
// .bullet .range.s0 { fill: #12D681; }
// .bullet .range.s1 { fill: #7EF3C2; }
// .bullet .range.s2 { fill: #66666699; }
// .bullet .measure.s0 { fill: steelblue;//display: none; }
// .bullet .title { font-size: 14px; font-weight: bold; }
// .bullet .subtitle { fill: #999; }
.bullet .secondaryLine1,
.secondaryLine2,
.secondaryLine3 {
  stroke: #66666699;
  stroke-width: 5px;
  stroke-linecap: round;
}

.triangleImg{
  font-family: sans-serif;
}

.bulletChartNoPointColor{
  color: var( --light-grey);
}

.bulletChartNoPointRect{
  width: 130px;
  height: 15px;
  margin-bottom: 3px;
}

.bulletChartNoPointRect1{
  width: 96px;
  height: 15px;
  margin-bottom: 3px;
}


.bulletChartSetPointNoData {
  background: var( --light-grey);
  padding: 8px;
  border-radius: 20px;
  margin: 20px 20px 0px;
}

.bulletChartSetPointNoDataTitle{
  margin-top: 12px;
    background: var( --light-grey);
    padding: 8px;
    border-radius: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.compressorPointStyle1:nth-child(even) {
  padding-left: 30px!important;
  padding-right: 0px !important;
}

.compressorPointStyle1:nth-child(odd) {
  padding-left: 0px !important;
  padding-right: 30px!important;
}

.m-l-115 {
  margin-left: 180px !important;
}

.plantName {
  font-weight: 600 !important;
}

.onHoverEquipBar{
  font-size: 1.65rem!important;
  position: absolute;
  background: rgba(32, 32, 32, 0.9);
  backdrop-filter: blur(16px);
  text-transform: uppercase;
  font-family: Oswald,Lato;
  font-weight: 400;
  min-width: 305px!important;
  max-height: 505px !important;
  z-index: 8;
  bottom: 0px !important;
  min-height: 36px;
  left: -50px;
  border-right: 4px solid var(--dark-grey);
  border-left: 4px solid var(--dark-grey);
  overflow-y: auto;
}

.onHoverEquipBar-childHeading{
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
  color: var( --light-grey);
  margin-left: 10px;
}

.onHoverEquipBar-child1, .onHoverEquipBar-child2{
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  color: var(--white-color);
  margin-left: 10px;
}

.onHoverEquipBar-child2-headerpipe{
  margin-left: 10px !important;
}
.onHoverEquipBar-child2-equip{
  margin-left: 0px !important;
  line-height: 1.5;
}

.onHoverEquipBar-child2Para {
  line-height: 1px;
  margin-top: 1px;
  margin-bottom: 18px;
  display: flex;
}

.onHoverEquipBar-L{
  color: var(--white-color);
  margin-left: 13px;
  font-size: 16px;
}

.onHoverEquipBar-name{
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--white-color);
  margin-left: 10px;
  margin-bottom: -8px;
  margin-top: 5px;
}

.ng-tooltip {
  position: fixed;
  min-width: 80px;
  color: var(--white-color);
  background:var(--dark-grey);
  font-family: Oswald,Lato;
  font-weight: 400;
  z-index: 1000;
  opacity: 0;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
  display: none !important;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}


// Bottom equip bar on hover tooltip  end

// input.mat-input-element {
//   height: 41px !important;
//   padding-left: 13px !important;
//   top: 0;
//   position: sticky;
//   background-color: white;
//   z-index: 100;
//   // border-bottom: 1px solid var(--line) !important;
// }
.header-org{
  input.mat-input-element {
    border-bottom: 1px solid var(--line) !important;
  }
}

.drawflow-delete{
  color: white !important;
  background: #e55618 !important;
  font-size: 38px !important;
  padding-top: 3px;
}

.hoverLoaderDiv {
  text-align: center;
  margin-top: 70px;
}

.sharedDropDown {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  pointer-events: none !important;
}

.sharedSetPointDropDown {
  line-height: 10px !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  pointer-events: none !important;
}

.cpm-masterview-equip-modal-container {
  .mat-select-arrow {
    color: var(--primary) !important;
  }
}

// warning icon styling.
.waterCooledChiller, .airCooledChiller {
  .warning-icon {
    color: var(--primary);
    opacity: 60%;
    font-size: 5rem;
    position: absolute;
    top: 446px;
    left: 325px;
    z-index: 1111;
    &:hover{
      opacity: 100%;
    }
  }
}

.temperatureSensor_backward, .temperatureSensor_forward {
  .warning-icon {
    position: absolute;
    font-size: 2rem;
    color: var(--primary);
    opacity: 60%;
    top: 35px;
    left: 0px;
    z-index: 1111;
    &:hover{
      opacity: 100%;
    }
  }
}

.pump1Vertical_backward, .pump1Vertical_forward {
  .warning-icon {
    font-size: 3rem;
    color: var(--primary);
    opacity: 60%;
    top: 170px;
    position: absolute;
    left: 140px;
    z-index: 1111;
    &:hover{
      opacity: 100%;
    }
  }
}

.diffPressureSensor_forward, .diffPressureSensor_backward {
  .warning-icon {
    position: absolute;
    font-size: 2rem;
    color: var(--primary);
    opacity: 60%;
    top: 60px;
    left: 25px;
    z-index: 1111;
    &:hover{
      opacity: 100%;
    }
  }
}

.valveActuator_forward, .valveActuator_backward {
  .warning-icon {
    font-size: 2.5rem;
    color: var(--primary);
    opacity: 60%;
    top: 75px;
    position: absolute;
    left: 30px;
    z-index: 1111;
    &:hover{
      opacity: 100%;
    }
  }
}

.flowSensor_forward, .flowSensor_backward {
  .warning-icon {
    position: absolute;
    font-size: 2.5rem;
    color: var(--primary);
    opacity: 60%;
    top: 65px;
    left: 50px;
    z-index: 1111;
    &:hover{
      opacity: 100%;
    }
  }
}

.coolingTowerSingleFan, .coolingTowerTwoFan, .coolingTowerFourFan {
  .warning-icon {
    position: absolute;
    top: 450px;
    left: 450px;
    font-size: 5rem;
    color: var(--primary);
    opacity: 60%;
    z-index: 1111;
    &:hover{
      opacity: 100%;
    }
  }
}

.condenserBoiler {
  .warning-icon {
    font-size: 5rem;
    position: absolute;
    color: var(--primary);
    opacity: 60%;
    top: 350px;
    left: 300px;
    z-index: 1111;
    &:hover{
      opacity: 100%;
    }
  }
}

.btuMeter {
  .warning-icon {
    top: 70px;
    left: 10px;
    font-size: 2.5rem;
    position: absolute;
    color: var(--primary);
    opacity: 60%;
    z-index: 1111;
    &:hover{
      opacity: 100%;
    }
  }
}
.threeWayValveDivert_backward,
.threeWayValvemixing_backward,
.threeWayValveDivert_forward,
.threeWayValvemixing_forward{
  .warning-icon {
    font-size: 2.5rem;
    color: var(--primary);
    opacity: 60%;
    top: 75px;
    position: absolute;
    left: 30px;
    z-index: 1111;
    &:hover{
      opacity: 100%;
    }
  }
}

.building2Pipe, .building2PipeHot, .dualTempBuilding2Pipe {
  .warning-icon {
    font-size: 5rem;
    position: absolute;
    color: var(--primary);
    opacity: 60%;
    top: 330px;
    left: 210px;
    z-index: 1111;
    &:hover{
      opacity: 100%;
    }
  }
}

.exclamation-triangle-fill {
  fill: var(--primary);
  opacity: 60%;
  z-index: 1111;
  &:hover{
    opacity: 100%;
  }
}

.tooltip_text {
  width: 350px;
  background-color: rgba(32, 32, 32, 0.9);
  color: var(--lighter-grey);
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 15px;
  opacity: 100%;
  height: 60px;
  visibility: hidden;
  top: -30px;
  left: 65px;
}

.warning-icon:hover .tooltip_text {
  visibility: visible;
}

#warningIconTooltipOnPipe {
  background-color: rgba(32, 32, 32, 0.9);
  border-radius: 6px;
  padding: 10px;
  color: var(--lighter-grey);
  height: 60px;
  width: 350px;
}

.svg_warning-icon {
  z-index: 1111;
}


.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  visibility: hidden !important;
}

.gtranslate_wrapper {
  visibility: hidden !important;
}
